import styled from "styled-components";

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
`;

export default Card;
