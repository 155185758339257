import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";

type Props = {
  turns: number;
};

const Statistics: FunctionComponent<Props> = ({ turns }) => {
  const [timer, setTimer] = useState(0);

  const timerCallback = useCallback(() => {
    setTimer(timer + 1);
  }, [timer]);

  useEffect(() => {
    if (!turns) return;
    const interval = setInterval(timerCallback, 1000);
    return () => clearInterval(interval);
  }, [turns, timerCallback]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>Turns: {turns}</div>
      <div>Time: {timer}s</div>
    </div>
  );
};

export default Statistics;
