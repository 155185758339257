import palette from "google-palette";
import { range } from "lodash";

export const prepareField = (colsCount: number) => {
  const colors = palette("tol-sq", Math.pow(colsCount, 2));

  const map: Record<string, Cell> = {};
  let index = 0;
  range(colsCount).forEach((x) => {
    range(colsCount).forEach((y) => {
      const id = Math.random().toString().substr(2);
      map[id] = {
        id,
        position: `${x}-${y}`,
        color: `#${colors[index++]}`,
      };
    });
  });

  return map;
};
