import { isEqual, shuffle } from "lodash";
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { prepareField } from "../utils/colors";
import Card from "./Card";
import Game from "./Game";
import Statistics from "./Statistics";

const CANVAS_SIZE = 500;
const COLS_COUNT = Math.round(CANVAS_SIZE / 100);

const Battlefield: FunctionComponent<{}> = () => {
  const [turns, setTurns] = useState(0);
  const [map, setMap] = useState<Cell[]>([]);
  const [index, setIndex] = useState<string[]>([]);
  const [originalOrder, setOriginalOrder] = useState<string[]>([]);
  const [firstMoveTime, setFirstMoveTime] = useState(0);
  const [showLabels, setShowLabels] = useState(false);
  const [gameInProgress, setGameInProgres] = useState(true);
  const [isControlsDisplayed, setIsControlsDisplayed] = useState(false);

  const onLabelsChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShowLabels(event.target.checked);
  }, []);

  const onGameInit = useCallback(() => {
    setTurns(0);
    setFirstMoveTime(0);

    const field = prepareField(COLS_COUNT);
    const [ids, map] = [Object.keys(field), Object.values(field)];

    setMap(map);
    setOriginalOrder(ids);
    setIndex(shuffle(ids));
  }, []);

  const onMovement = useCallback(
    (sourceId: string, destinationId: string) => {
      const alteredIndex = [...index];

      const sourceIndex = index.findIndex((x) => x === sourceId);
      const destinationIndex = index.findIndex((x) => x === destinationId);

      if (sourceIndex !== destinationIndex) {
        alteredIndex[sourceIndex] = destinationId;
        alteredIndex[destinationIndex] = sourceId;

        setIndex(alteredIndex);
        setTurns(turns + 1);
        if (!firstMoveTime) setFirstMoveTime(Date.now());

        if (isEqual(alteredIndex, originalOrder)) {
          console.log((Date.now() - firstMoveTime) / 1000);
          setGameInProgres(false);
        }
      }
    },
    [index, originalOrder, firstMoveTime, turns]
  );

  const onPlayAgain = useCallback(() => {
    setGameInProgres(true);
    onGameInit();
  }, [onGameInit]);

  const onShowEasterEgg = useCallback(() => {
    setIsControlsDisplayed(true);
  }, []);

  useEffect(() => {
    onGameInit();
  }, [onGameInit]);

  return (
    <>
      {gameInProgress ? (
        <>
          <Game
            map={map}
            index={index}
            canvasSize={CANVAS_SIZE}
            colsCount={COLS_COUNT}
            onMovement={onMovement}
            showLabels={showLabels}
            onShowEasterEgg={onShowEasterEgg}
          />
          {isControlsDisplayed && (
            <div>
              <Statistics turns={turns} />
              <label>
                <input
                  id="lables"
                  type="checkbox"
                  checked={showLabels}
                  onChange={onLabelsChange}
                />{" "}
                Show lables
              </label>
            </div>
          )}
        </>
      ) : (
        <Card>
          <h1>
            You won with in {turns} turns for{" "}
            {(Date.now() - firstMoveTime) / 1000} seconds
          </h1>
          <button onClick={onPlayAgain}>Play again</button>
        </Card>
      )}
    </>
  );
};

export default Battlefield;
