import React, { FunctionComponent } from "react";
import Battlefield from "./components/Battlefield";
import Container from "./components/Container";
// import Colours from "./Colours";

const App: FunctionComponent<{}> = () => {
  return (
    <Container>
      <Battlefield />
      {/* <Colours /> */}
    </Container>
  );
};

export default App;
