import styled from "styled-components";

type OwnProps = {
  size: number;
  color: string;
};

const Cell = styled.div`
  width: ${(props: OwnProps) => props.size}px;
  height: ${(props: OwnProps) => props.size}px;
  background-color: ${(props: OwnProps) => props.color};
`;

export default Cell;
