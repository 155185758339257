import React, {
  FunctionComponent,
  DragEvent,
  useCallback,
  useState,
  useMemo,
} from "react";
import Grid from "./Grid";
import Cell from "./Cell";

type Props = {
  canvasSize: number;
  colsCount: number;
  showLabels: boolean;
  map: Cell[];
  index: string[];
  onMovement: (sourceId: string, destinationId: string) => void;
  onShowEasterEgg: () => void;
};

const Game: FunctionComponent<Props> = ({
  colsCount,
  index,
  onMovement,
  canvasSize,
  map,
  showLabels,
  onShowEasterEgg,
}) => {
  const [activeId, setActiveId] = useState<string>();
  const width = useMemo(() => Math.floor(canvasSize / colsCount), [
    canvasSize,
    colsCount,
  ]);

  const onDropOver = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      const destinationId = (event.target as HTMLDivElement).dataset.id;

      if (destinationId && activeId) {
        onMovement(activeId, destinationId);
      }
    },
    [activeId, onMovement]
  );

  const onDragStart = useCallback((event: DragEvent<HTMLDivElement>) => {
    const sourceId = (event.target as HTMLDivElement).dataset.id;
    setActiveId(sourceId);
  }, []);

  const getCellFromIndex = useCallback(
    (id: string) => map.find((x) => x.id === id) as Cell,
    [map]
  );

  return (
    <Grid
      size={canvasSize}
      cols={colsCount}
      onDrop={onDropOver}
      onDoubleClick={onShowEasterEgg}
    >
      {index.map(getCellFromIndex).map(({ id, position, color }) => (
        <Cell
          key={id}
          data-id={id}
          data-position={position}
          draggable
          onDragStart={onDragStart}
          color={color}
          size={width}
        >
          {showLabels ? position : null}
        </Cell>
      ))}
    </Grid>
  );
};

export default Game;
