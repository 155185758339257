import styled from "styled-components";
import { range } from "lodash";

type OwnProps = {
  size: number;
  cols: number;
};

const Grid = styled.div`
  width: ${(props: OwnProps) => props.size}px;
  height: ${(props: OwnProps) => props.size}px;
  display: grid;
  grid-template-rows: ${(props: OwnProps) =>
    range(props.cols)
      .map((_) => "1fr")
      .join(" ")};
  grid-template-columns: ${(props: OwnProps) =>
    range(props.cols)
      .map((_) => "1fr")
      .join(" ")};
`;

export default Grid;
